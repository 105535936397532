import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../components/css/home.css';
import '../components/css/style.css'; 

const truncateText = (text, wordLimit) => {
  const words = text.split(' ');
  if (words.length <= wordLimit) return text;
  return words.slice(0, wordLimit).join(' ') + '...';
};

const HomePage = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');

  const handleLogout = () => {
    localStorage.removeItem('loggedIn');
    navigate('/login');
  };



  return (
    <div>
<nav className="navbar navbar-expand-lg navbar-dark bg-primary">
        <div className="container">
          <a className="navbar-brand" href="#">My App</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a className="nav-link" href="/">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">Legal Tips</a>
                {/* <a className="nav-link" href="/legal-tips">Legal Tips</a> */}
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">True Stories</a>
                {/* <a className="nav-link" href="/true-stories">True Stories</a> */}
              </li>
              <li className="nav-item">
                <button className="btn btn-outline-light" onClick={handleLogout}>Logout</button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

<div class="breadcrumb-wrap">
    <div class="container">
        <ul class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active">Legal Tips</li>
        </ul>
    </div>
</div>

<div class="single-news">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="sn-container">
                    <div class="sn-img">
                        <img src="img/news-825x525.jpg" />
                    </div>
                    <div class="sn-content">
                        <h1 class="sn-title">ከውርስ መነቀል (ከወራሽነት መነሳት)</h1>
                        <p>
                        በኢትዮጵያ የውርስ ህግ፣ ተናዛዡ በፈቃዱ አንድ ወይም ብዙ ወራሾቹን በተመለከተ ሰፋ ያለ መብቶች አሉት። መብቶቹ ሁሉንም ልጆቹን ውርስ እስከማንሳት ድረስ ሊሄዱ ይችላሉ። ከውራሽነት መንቀል ማለት  ወራሾቹን ከውርስ ለማግለል በተናዛዡ የሚተላለፈ ትእዛዝ ነው። ብዙውን ጊዜ ለወራሾቹ መጥፎ ባህሪ የቅጣት ዘዴ ሆኖ ያገለግላል።ተናዛዡ ወራሾቹን በግልፅም ሆነ በሌላ ዘዴ ሊሰርዝ ይችላል።
                         </p>
                        <p>
                        ግልጽ ከውርስ መነቀል ማለት ተናዛዡ ወራሹን በግልፅ ወራሹን ከውርስ ማናሳቱን በመግለጽ ከውርስ የሚያገልበት ነው። ተናዛዡ ሁሉንም ወራሾቹን (ልጆቹን እና ሌሎች ወራሾችን) በግልፅ ውርስ ሊሰርዝ እና ሁለንተናዊ ተወካይ  ሊሾም ይችላል። ለምሳሌ አቶ ዋጋው ሁሉንም ልጆቹን እና ሌሎች ወራሾችን ከውርስ ነቅሎ ወዳጁን ጎሳዬን  ሁለንተናዊ ተወካይ አድርጎ ሊሾም ይችላል። እንዲህ ባለ ሁኔታ የአቶ ዋጋውን ሙሉ ርስት ያለ ተከራካሪ ጎሳዬ እንዲቀበል ተጠርቷል። ተናዛዡ ሁሉንም ወራሾች ውርስ ከሰረዘ እና አንድን ሰው እንደ ሁለንተናዊ ውርስ ካልሾመ፣ ርስቱን የሚወስድ ማንም አይኖርም። በእንደዚህ ዓይነት ሁኔታዎች ውስጥ, ህጉ እንደዚህ ያሉ ውርስ የሌላቸው ወራሾች ዘሮች የተናዛዡን ንብረት በውክልና እንዲወስዱ የሚያስችል ዘዴ ፈጥሯል (የኢ/ያ ፍታብሔር ህግ አንቀጽ 937 ይመልከቱ)
                        </p>
                        <p>
                        አንድ ተናዛዥልጆቹን ከውርስ ሊነቅል የሚችለው በግልፅ መንገድ ብቻ ነው። ማለትም የትኛውም ልጅ ግልጽ ከሆነ መንገድ ውጭ በሌላ ዘዴ ከውርስ ሊነቀል  አይችልም። በተጨማሪም፣ ተናዛዡ ልጆቹን ከውርስ ለመንቀል የወሰነበትን ትክክለኛ ምክንያት በግልፅ ያስቀምጣል። ሕጉ የልጅን ከእናት ከአባት የመውረስ ጥቅም ለማስጠበቅ በማሰብ በተናዛዡ ላይ እንዲህ ያለ ጫና ያደርጋል።</p>
                        
                        <p>
                        የተናዛዡን አንድ ወይም ከዚያ በላይ ልጆቹን ከውርስ ለመነቀል አሳማኝ ምክንያት (ዎች) መስጠት እንዳለበት ከዚህ በላይ ተነግሯል። ነገር ግን ሕጉ አንድን ምክንያት አሳማኝ የሚያስብሉ እና የማያስብሉ ህጋዊ መሰረቶችን አልዘረዘረም። ምክንያታዊነት ያለው ተጨባጭ ደረጃ የለውም። ነገር ግን ምክንያታዊ የሆነን ሰው መማረክ እንዳለበት ይታመናል፡፡ </p>
             
                    </div>
                </div>
                <div class="sn-related">
                    <h2>Related News</h2>
                    <div class="row sn-slider">
                        <div class="col-md-4">
                            <div class="sn-img">
                                <img src="img/news-350x223-1.jpg" />
                                <div class="sn-title">
                                    <a href="">Interdum et fames ac ante</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="sn-img">
                                <img src="img/news-350x223-2.jpg" />
                                <div class="sn-title">
                                    <a href="">Interdum et fames ac ante</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="sn-img">
                                <img src="img/news-350x223-3.jpg" />
                                <div class="sn-title">
                                    <a href="">Interdum et fames ac ante</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="sn-img">
                                <img src="img/news-350x223-4.jpg" />
                                <div class="sn-title">
                                    <a href="">Interdum et fames ac ante</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="sidebar">
                    <div class="sidebar-widget">
                        <h2 class="sw-title">በዚህ ምድብ ውስጥ</h2>
                        <div class="news-list">
                            <div class="nl-item">
                                <div class="nl-img">
                                    <img src="img/news-350x223-1.jpg" />
                                </div>
                                <div class="nl-title">
                                    <a href="">ስለሥራ ስንብት ክፍያና ስለካሳ ክፍያ በተመለከተ </a>
                                </div>
                            </div>
                            <div class="nl-item">
                                <div class="nl-img">
                                    <img src="img/news-350x223-2.jpg" />
                                </div>
                                <div class="nl-title">
                                    <a href="">ማቀባበል (Facilitating Act of Bribery) (ለጉቦኞች አይላላኩ፤ ከተላኩ ዘብጥያ ይላካሉ!)</a>
                                </div>
                            </div>
                            <div class="nl-item">
                                <div class="nl-img">
                                    <img src="img/news-350x223-3.jpg" />
                                </div>
                                <div class="nl-title">
                                    <a href="">ስለባልና ሚስት የጋራ እና የግል ንብረቶች በተመለከተ</a>
                                </div>
                            </div>
                            <div class="nl-item">
                                <div class="nl-img">
                                    <img src="img/news-350x223-4.jpg" />
                                </div>
                                <div class="nl-title">
                                    <a href="">በወንጀል ጉዳይ በዋስትና እንዲፈታ ፍርድ ቤት ያዘዘውን ሰው ፖሊስ ይግባኝ</a>
                                </div>
                            </div>
                            <div class="nl-item">
                                <div class="nl-img">
                                    <img src="img/news-350x223-5.jpg" />
                                </div>
                                <div class="nl-title">
                                    <a href="">ተገዶ የመያዝን ሕጋዊነት ለማጣራት የሚቀርብ ክስ</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
    
                    
                    <div class="sidebar-widget">
                        <div class="tab-news">
                            <ul class="nav nav-pills nav-justified">
                                <li class="nav-item">
                                    <a class="nav-link active" data-toggle="pill" href="#featured">Popular</a>
                                </li>

                            </ul>

                            <div class="tab-content">
                                <div id="featured" class="container tab-pane active">
                                    <div class="tn-news">
                                        <div class="tn-img">
                                            <img src="img/news-350x223-1.jpg" />
                                        </div>
                                        <div class="tn-title">
                                            <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                        </div>
                                    </div>
                                    <div class="tn-news">
                                        <div class="tn-img">
                                            <img src="img/news-350x223-2.jpg" />
                                        </div>
                                        <div class="tn-title">
                                            <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                        </div>
                                    </div>
                                    <div class="tn-news">
                                        <div class="tn-img">
                                            <img src="img/news-350x223-3.jpg" />
                                        </div>
                                        <div class="tn-title">
                                            <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                        </div>
                                    </div>
                                    <div class="tn-news">
                                        <div class="tn-img">
                                            <img src="img/news-350x223-4.jpg" />
                                        </div>
                                        <div class="tn-title">
                                            <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                        </div>
                                    </div>
                                    <div class="tn-news">
                                        <div class="tn-img">
                                            <img src="img/news-350x223-5.jpg" />
                                        </div>
                                        <div class="tn-title">
                                            <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                        </div>
                                    </div>
                                </div>
                                <div id="popular" class="container tab-pane fade">
                                    <div class="tn-news">
                                        <div class="tn-img">
                                            <img src="img/news-350x223-4.jpg" />
                                        </div>
                                        <div class="tn-title">
                                            <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                        </div>
                                    </div>
                                    <div class="tn-news">
                                        <div class="tn-img">
                                            <img src="img/news-350x223-3.jpg" />
                                        </div>
                                        <div class="tn-title">
                                            <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                        </div>
                                    </div>
                                    <div class="tn-news">
                                        <div class="tn-img">
                                            <img src="img/news-350x223-2.jpg" />
                                        </div>
                                        <div class="tn-title">
                                            <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                        </div>
                                    </div>
                                    <div class="tn-news">
                                        <div class="tn-img">
                                            <img src="img/news-350x223-1.jpg" />
                                        </div>
                                        <div class="tn-title">
                                            <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                        </div>
                                    </div>
                                    <div class="tn-news">
                                        <div class="tn-img">
                                            <img src="img/news-350x223-2.jpg" />
                                        </div>
                                        <div class="tn-title">
                                            <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                        </div>
                                    </div>
                                </div>
                                <div id="latest" class="container tab-pane fade">
                                    <div class="tn-news">
                                        <div class="tn-img">
                                            <img src="img/news-350x223-3.jpg" />
                                        </div>
                                        <div class="tn-title">
                                            <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                        </div>
                                    </div>
                                    <div class="tn-news">
                                        <div class="tn-img">
                                            <img src="img/news-350x223-4.jpg" />
                                        </div>
                                        <div class="tn-title">
                                            <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                        </div>
                                    </div>
                                    <div class="tn-news">
                                        <div class="tn-img">
                                            <img src="img/news-350x223-5.jpg" />
                                        </div>
                                        <div class="tn-title">
                                            <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                        </div>
                                    </div>
                                    <div class="tn-news">
                                        <div class="tn-img">
                                            <img src="img/news-350x223-4.jpg" />
                                        </div>
                                        <div class="tn-title">
                                            <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                        </div>
                                    </div>
                                    <div class="tn-news">
                                        <div class="tn-img">
                                            <img src="img/news-350x223-3.jpg" />
                                        </div>
                                        <div class="tn-title">
                                            <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    


                </div>
            </div>
        </div>
    </div>
</div></div>
  );
};

export default HomePage;
