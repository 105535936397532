 import { BrowserRouter as Router,Route, Routes } from 'react-router-dom';
// import Navbar from './components/Navbar';
import React from 'react';
import Login from './components/Login';
import HomePage from './components/HomePage';
import LegalTips from './components/LegalTips';
import TrueStories from './components/TrueStories';
import ContentDetail from './components/ContentDetail';
import ContentDetailPage from './components/ContentDetailPage';

import ProtectedRoute from './ProtectedRoute'; 
// import ArticleList from './components/ArticleList';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        
        {/* Protected Routes */}
        <Route path="/" element={<ProtectedRoute element={<HomePage />} />} />
        <Route path="/content/:id" element={<ProtectedRoute element={<ContentDetailPage />} />} />
        <Route path="/legal-tips" element={<LegalTips />} />
        <Route path="/true-stories" element={<TrueStories />} />
        <Route path="/details/:id" element={<ContentDetail />} />
        
        {/* Add more protected routes here */}
      </Routes>
    </Router>
  );
};

export default App;