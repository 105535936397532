import React from 'react';

const TrueStories = () => {
  return (
    <div className="container my-5">
      <h2 className="mb-4">True Stories</h2>
      <p>Read real-life stories that provide insights and experiences related to legal matters.</p>
      {/* Add your content here */}
    </div>
  );
};

export default TrueStories;
