import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './css/login.css'

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [agreedToPolicy, setAgreedToPolicy] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');

    if (!agreedToPolicy) {
      setError(t('You must agree to the privacy policy to log in.'));
      return;
    }

    if (phoneNumber === '1234567890' && password === '123456') {
      localStorage.setItem('loggedIn', 'true');
      navigate('/');
    } else {
      setError(t('Invalid credentials'));
    }
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      {/* Header */}
      <header className="bg-primary text-white py-3">
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
          <div className="container">
            <span className="ms-2 fs-2 fw-bold">{t('NegerFej')}</span>
            <LanguageSwitcher /> {/* Include LanguageSwitcher */}
          </div>
        </nav>
      </header>

      {/* Main Content */}
      <div className="container my-5 flex-grow-1">
        <div className="row align-items-center">
          <div className="col-md-6">
            <header className="py-3">
              <div className="container">
                <h1 className="text-center">{t('Legal Tips and Insights')}</h1>
              </div>
            </header>
            <div className="row">
              <h2 className="mb-4">{t('Empowering Your Legal Knowledge')}</h2>
              <p className="mb-4">
                {t('Stay informed with our expert legal tips and professional insights tailored to your daily activities. Our content is crafted by legal professionals and includes real-life stories to help you understand and navigate legal challenges effectively.')}
              </p>
              <h3 className="mb-3">{t('What We Offer')}</h3>
              <ul className="list-unstyled">
                <li className="mb-2">
                  <i className="bi bi-check-circle text-success"></i> {t('Daily Legal Tips')}
                </li>
                <li className="mb-2">
                  <i className="bi bi-check-circle text-success"></i> {t('Expert Insights from Legal Professionals')}
                </li>
                <li className="mb-2">
                  <i className="bi bi-check-circle text-success"></i> {t('Real-life Legal Stories')}
                </li>
                <li className="mb-2">
                  <i className="bi bi-check-circle text-success"></i> {t('Comprehensive Guides and Resources')}
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="login-card">
              <h2 className="mb-4">{t('Login')}</h2>
              {error && (
                <div className="alert alert-danger" role="alert" aria-live="assertive">
                  {error}
                </div>
              )}
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="phoneNumber" className="form-label">{t('Phone Number')}</label>
                  <input
                    type="tel"
                    className="form-control"
                    id="phoneNumber"
                    placeholder={t('Enter phone number')}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    pattern="[0-9]{10}"
                    required
                  />
                </div>
  
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">{t('Password')}</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder={t('Password')}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    minLength={6}
                    required
                  />
                </div>
  
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="privacyPolicyCheck"
                    checked={agreedToPolicy}
                    onChange={(e) => setAgreedToPolicy(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="privacyPolicyCheck">
                    {t('I agree to the')} <a href="#!" onClick={() => setShowModal(true)}>{t('privacy policy')}</a>
                  </label>
                </div>
  
                <button type="submit" className="btn btn-primary">
                  {t('Login')}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-dark text-white text-center py-3 mt-auto">
        <p>&copy; 2024 {t('My App')}. {t('All rights reserved.')}</p>
      </footer>

      {/* Privacy Policy Modal */}
      <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" style={{ display: showModal ? 'block' : 'none' }} aria-hidden={!showModal}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t('Privacy Policy')}</h5>
              <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>{t('Here is the privacy policy of My App. Please read it carefully.')}</p>
              {/* Add more content about the privacy policy here */}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>{t('Close')}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;